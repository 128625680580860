import React, { useEffect } from "react";

import { navigate } from "@utils/navigate";
import { createAccountView, ViewComponent } from "@account/view";
import PersonalView from "./personal";


const Account: ViewComponent = (props) => {

    useEffect(() => {
        navigate("/account/personal", {
            replace: true,
        });
    }, []);

    return (
        <PersonalView {...props} />
    );
};

export default createAccountView(Account, {
    title: "Account",
});